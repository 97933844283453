import { Trans } from "@lingui/macro";
import { HomeSchemeEnum as Scheme } from "src/common/scheme";
import {
  P,
  Text,
  ButtonVariant,
  Space,
  HoverEffect,
  PlainLink,
} from "@gocardless/flux-react";
import BrandedLink from "src/components/shared/BrandedComponents/BrandedLink";
import { BrandedComponentType, getBrandColorFor } from "src/common/utils";
import { CSSForLinkAppearance } from "src/components/shared/BrandedComponents";

import { isIncludedPaymentScheme } from "./isIncludedPaymentScheme";

// All possible customisation keys must be declared here
// We're assuming that all schemes must have all the keys defined, which
// makes it type-safe. We can consider adding optional keys with a risk
type CustomisationKey =
  "billing-request.footer.gocardless-registration-information";

type SchemeCustomisations = {
  [scheme in Scheme]: {
    [key in CustomisationKey]: (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      params: any
    ) => React.ReactElement | null;
  };
};

export const PaymentSchemeMap = {
  FasterPayments: Scheme.FasterPayments,
  SepaCreditTransfer: Scheme.SepaCreditTransfer,
  SepaInstantCreditTransfer: Scheme.SepaInstantCreditTransfer,
  PayTo: Scheme.PayTo,
} as const;

type PaymentSchemeKeys = keyof typeof PaymentSchemeMap;

export type PaymentScheme = (typeof PaymentSchemeMap)[PaymentSchemeKeys];

const schemeCustomisations: Pick<SchemeCustomisations, PaymentScheme> = {
  [Scheme.FasterPayments]: {
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => {
      const brandColor = getBrandColorFor(
        BrandedComponentType.FooterLink,
        payerTheme,
        isDropinMode
      );

      return (
        <>
          <Text size={[1, 1, 1, 1]}>
            <Trans>
              Payments securely processed by GoCardless. GoCardless Ltd (company
              registration number 07495895) is authorised by the Financial
              Conduct Authority under the Payment Services Regulations 2017,
              registration number 597190, for the provision of payment services.
            </Trans>
          </Text>
          <Space v={1} />
          <Text size={[1, 1, 1, 1]}>
            <Trans>
              GoCardless uses personal data as described in their{" "}
              <PlainLink
                effect={HoverEffect.TextDecoration}
                textDecoration="underline"
                href="https://gocardless.com/privacy/"
                target="_blank"
                css={brandColor && { ...CSSForLinkAppearance(brandColor) }}
              >
                Privacy Notice.
              </PlainLink>
            </Trans>
          </Text>
        </>
      );
    },
  },

  [Scheme.SepaCreditTransfer]: {
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless SAS (company registration number 834 422 180, R.C.S. PARIS)
          is authorised by the ACPR (French Prudential Supervision and
          Resolution Authority), Bank Code (CIB) 17118, for the provision of
          payment services.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
  },

  [Scheme.SepaInstantCreditTransfer]: {
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <P>
          GoCardless SAS (company registration number 834 422 180, R.C.S. PARIS)
          is authorised by the ACPR (French Prudential Supervision and
          Resolution Authority), Bank Code (CIB) 17118, for the provision of
          payment services.
        </P>

        <P>
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </P>
      </Trans>
    ),
  },

  [Scheme.PayTo]: {
    "billing-request.footer.gocardless-registration-information": ({
      payerTheme,
      isDropinMode,
    }) => (
      <Trans>
        <Text>
          GoCardless Ltd (company registration number 07495895) is registered as
          a foreign company in Australia, ABN 17 606 261 74, and holds an
          Australian Financial Services licence (AFSL), number 478976.
        </Text>

        <Text>
          {" "}
          GoCardless uses personal data as described in our{" "}
          <BrandedLink
            textColor={getBrandColorFor(
              BrandedComponentType.FooterLink,
              payerTheme,
              isDropinMode
            )}
            variant={ButtonVariant.Inline}
            href="https://gocardless.com/privacy/"
            target="_blank"
          >
            Privacy Notice.
          </BrandedLink>
        </Text>
      </Trans>
    ),
  },
};

export const customiseForPaymentScheme = ({
  scheme,
  key,
  params,
}: {
  scheme: PaymentScheme;
  key: CustomisationKey;
  params?: object;
}) => {
  if (isIncludedPaymentScheme(scheme)) {
    return schemeCustomisations[scheme][key](params);
  }
  return null;
};
