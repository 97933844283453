import {
  AlignItems,
  Box,
  FontWeight,
  P,
  XYGrid,
  Space,
  ColorPreset,
  H2,
  TypePreset,
  Separator,
  Text,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import Amount from "src/legacy-ui/amount";
import {
  AvailableDebitSchemeEnum,
  SourceCurrencyEnum as Currency,
} from "@gocardless/api/dashboard/types";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { ReactNode, useState } from "react";
import { ToggleButton } from "src/components/shared/ToggleButton";
import { Routes } from "src/components/shared/Router";
import { PaymentVariant } from "src/common/payments";
import { TranslatedPaymentsCount } from "src/components/shared/components/TranslatedPaymentsCount";
import { Scheme } from "src/common/scheme";

import { ConsentTypeTag } from "../../../components/ConsentTypeTag";

export interface DescriptionProps {
  heading?: string | ReactNode;
  currency: Currency;
  scheme: AvailableDebitSchemeEnum;
  displayedConsentType: string | ReactNode;
  amount?: number;
  recurringPaymentsCount?: number | string;
  periodicityMessage?: ReactNode | string;
  toolTipContent?: string | ReactNode;
  paymentMethodDescriptions?: string[];
  mandateDescription?: string | ReactNode;
  creditorName?: string;
  toggleInstalmentDetails?: () => void;
  page?: Routes;
  paymentVariant?: PaymentVariant;
  bottomContent?: ReactNode;
}

export const DescriptionTemplate = ({
  heading,
  currency,
  scheme,
  amount,
  displayedConsentType,
  toolTipContent,
  creditorName,
  recurringPaymentsCount,
  periodicityMessage,
  mandateDescription,
  paymentMethodDescriptions,
  page,
  paymentVariant,
  bottomContent,
}: DescriptionProps) => {
  const isCustomerDetailsPage = page === Routes.CollectCustomerDetails;
  const renderShowDetailsToggle = paymentVariant === PaymentVariant.DualFlow;
  const [showDetails, setShowDetails] = useState(
    renderShowDetailsToggle ? isCustomerDetailsPage : true
  );

  const contentSections = [
    recurringPaymentsCount,
    periodicityMessage,
    mandateDescription,
    paymentMethodDescriptions,
  ];

  const onlyOneSectionShowing = contentSections.filter((v) => v).length === 1;
  const isPAD = scheme === Scheme.Pad;
  const showBankIcon = (isPAD && !onlyOneSectionShowing) || !isPAD;

  return (
    <Box css={{ position: "relative" }}>
      <Box spaceBelow={!isPAD && onlyOneSectionShowing ? 2 : 0}>
        <XYGrid rowGap={0.5}>
          {heading && (
            <H2
              data-testid="heading"
              preset={TypePreset.Heading_03}
              weight={FontWeight.SemiBold}
            >
              {heading}
            </H2>
          )}
          {amount && (
            <Box
              data-testid="amount"
              layout="flex"
              alignItems={AlignItems.Center}
            >
              <Amount currency={currency} amountInPence={amount} />
              <Space h={1} layout="inline" />
              <ConsentTypeTag
                displayedConsentType={displayedConsentType}
                toolTipContent={toolTipContent}
              />
            </Box>
          )}
          <Box flexGrow={1}>
            {showDetails && (
              <>
                {mandateDescription && (
                  <P
                    data-testid="mandate-description"
                    preset={TypePreset.Body_01}
                    color={ColorPreset.TextOnLight_01}
                    spaceBelow={!onlyOneSectionShowing ? 0.5 : 0}
                  >
                    {mandateDescription}
                  </P>
                )}
                {recurringPaymentsCount && (
                  <P
                    data-testid="payments-total"
                    size={1}
                    color={ColorPreset.TextOnLight_02}
                    weight={FontWeight.Normal}
                    spaceAbove={mandateDescription ? 0.5 : 0}
                  >
                    <TranslatedPaymentsCount
                      paymentsCount={recurringPaymentsCount}
                    />
                  </P>
                )}
                {periodicityMessage && (
                  <P
                    data-testid="periodicity-message"
                    size={1}
                    color={ColorPreset.TextOnLight_02}
                    weight={FontWeight.Normal}
                    spaceAbove={recurringPaymentsCount ? 0.5 : 0}
                  >
                    {periodicityMessage}
                  </P>
                )}
                {!!paymentMethodDescriptions?.length && (
                  <Box data-testid="payment-method-description">
                    <Separator
                      spacing={[[1, 0]]}
                      color={ColorPreset.BorderOnLight_04}
                    />
                    <XYGrid rowGap={0.5}>
                      {paymentMethodDescriptions.map((description, index) => (
                        <P
                          key={`description-${index}`}
                          preset={TypePreset.Body_01}
                          color={ColorPreset.TextOnLight_01}
                        >
                          {description}
                        </P>
                      ))}
                      <P
                        size={1}
                        color={ColorPreset.TextOnLight_02}
                        weight={FontWeight.Normal}
                      >
                        <Trans>Note from {creditorName}</Trans>
                      </P>
                    </XYGrid>
                  </Box>
                )}
              </>
            )}
            {renderShowDetailsToggle && (
              <Box spaceAbove={showDetails ? 0.5 : 0}>
                <ToggleButton
                  showDetails={showDetails}
                  setNextState={() => setShowDetails(!showDetails)}
                />
              </Box>
            )}
          </Box>
        </XYGrid>
      </Box>
      {showBankIcon && (
        <Box
          data-testid="bank-logo"
          layout="flex"
          alignItems={AlignItems.Center}
          css={{ position: "absolute", bottom: 0, right: 0 }}
        >
          {translateForScheme({
            scheme: scheme,
            translationKey: "collect-bank-account-page.bank-details-panel.logo",
            params: {},
          })}
        </Box>
      )}
      {bottomContent && (
        <Box spaceBelow={1}>
          <Separator spacing={[[1, 0]]} />
          <Text size={1} color={ColorPreset.TextOnLight_02}>
            {bottomContent}
          </Text>
        </Box>
      )}
    </Box>
  );
};
