import {
  AvailableDebitSchemeEnum,
  BankAuthorisationResource,
  BillingRequestFlowResource,
  BillingRequestResource,
  ConsentType,
  InstalmentTemplateResource,
  InstitutionResource,
  PaylinkResource,
  PlanResource,
} from "@gocardless/api/dashboard/types";
import {
  AlignItems,
  Box,
  Button,
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  Checkbox,
  Color,
  ColorPreset,
  Dialog,
  FontWeight,
  Glyph,
  H2,
  H4,
  HoverEffect,
  Interpose,
  JustifyContent,
  LabelledPanel,
  LI,
  P,
  PlainButton,
  PlainLink,
  Separator,
  Space,
  Text,
  TextAlign,
  TypePreset,
  UL,
  XYGrid,
} from "@gocardless/flux-react";
import { Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { ChangeEvent, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import { BANK_AUTHORISATION_FLOW_TYPE } from "src/common/consts";
import { getTranslatedLink, Link } from "src/common/links";
import { DirectDebitSchemes } from "src/common/scheme";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";
import { translateForScheme } from "src/common/scheme-translations/translateForScheme";
import { customiseForScheme, Scheme } from "src/common/schemeCustomisations";
import {
  BrandedComponentType,
  customerDetailsExist,
  getBrandColorFor,
  isOneOffPaymentFlow,
  isSepaIBPScheme,
} from "src/common/utils";
import BankAuthorisationLink from "src/components/BankAuthorisationLink";
import ViewESignTerms from "src/components/pages/BankConfirm/ViewESignTerms";
import BillingRequestDescription from "src/components/shared/BillingRequestDescription";
import { PADLegalDisclaimer } from "src/components/shared/BillingRequestDescription/EducationWrapper/PADLegalDisclaimer";
import BrandedButton from "src/components/shared/BrandedComponents/BrandedButton";
import BrandedLink from "src/components/shared/BrandedComponents/BrandedLink";
import TermsAndCondition from "src/components/shared/Modals/TermsAndConditions";
import { useShouldDisplayCustomerPreferences } from "src/components/shared/PayerPreference/preferences";
import { Routes } from "src/components/shared/Router";
import TermsAndConditions from "src/components/shared/TermsAndConditions";
import { PayerTermsLink } from "src/components/shared/V2/TermsAndConditions";
import { PayerThemeType } from "src/state";
import { RuntimeMode } from "src/state/RuntimeModeInitialiser";

import BankDetails from "./BankDetails";
import ConfirmCustomerDetails from "./ConfirmCustomerDetails";
import CreditorDetails from "./CreditorDetails";
import PaymentAgreementBody from "./PaymentAgreementBody";
import SelfPaymentAuthorisationCheck from "./SelfPaymentAuthorisationCheck";
import ViewDirectDebit from "./ViewDirectDebit";

interface BankConfirmViewProps {
  billingRequest: BillingRequestResource;
  bankAuthorisation?: BankAuthorisationResource;
  paylink: PaylinkResource | undefined;
  billingRequestFlow: BillingRequestFlowResource;
  selectedInstitution?: InstitutionResource;
  plan?: PlanResource;
  instalmentTemplate?: InstalmentTemplateResource;
  onAddAnotherAccount: () => void;
  onChangeInstitution: () => void;
  onEditCustomerDetails: () => void;
  onConfirm: () => void;
  setIsSelfAuthorised: (isSelfAuthorised: boolean) => void;
  setRememberPayerDetails: (rememberPayerDetails: boolean) => void;
  mandatePDFLink?: string;
  payerTheme?: PayerThemeType;
  sendEvent: (name: string, params?: {}) => void;
  push: (route: Routes, keyvals: Record<string, unknown>) => void;
  isSelfAuthorised: boolean;
  shouldShowSelfAuthorisationCheck: boolean;
  preventRedirectingToBank?: boolean;
  runtimeMode?: RuntimeMode;
}

export interface BankConfirmViewBodyProps {
  billingRequest: BillingRequestResource;
  billingRequestFlow: BillingRequestFlowResource;
  bankAuthorisation?: BankAuthorisationResource;
  selectedInstitution?: InstitutionResource;
  plan?: PlanResource;
  instalmentTemplate?: InstalmentTemplateResource;
  paylink: PaylinkResource | undefined;
  onChangeInstitution: () => void;
  onAddAnotherAccount: () => void;
  onEditCustomerDetails: () => void;
  setRememberPayerDetails: (rememberPayerDetails: boolean) => void;
  payerTheme?: PayerThemeType;
  shouldShowCreditorDetails?: boolean;
  scheme: AvailableDebitSchemeEnum;
}

const eSignTermsLink =
  "https://assets.ctfassets.net/40w0m41bmydz/7h0wPfCRdbE9Rg8Ortu2cY/634522475874ec11a01bf9890bf8e483/GoCardless_E-Sign_Terms_-_Payers_-_October_2023.pdf";

const BankConfirmView = ({
  billingRequest,
  billingRequestFlow,
  bankAuthorisation,
  selectedInstitution,
  plan,
  instalmentTemplate,
  onChangeInstitution,
  onAddAnotherAccount,
  onEditCustomerDetails,
  onConfirm,
  mandatePDFLink,
  payerTheme,
  paylink,
  sendEvent,
  setIsSelfAuthorised,
  setRememberPayerDetails,
  isSelfAuthorised,
  shouldShowSelfAuthorisationCheck,
  runtimeMode,
  push,
  preventRedirectingToBank = false,
}: BankConfirmViewProps) => {
  const { i18n } = useLingui();

  const [acceptedTermsAndConditions, setAcceptedTermsAndConditions] =
    useState(false);
  const [termsAndConditionsError] = useState(false);
  const onChecked = (checkedState: boolean) => {
    setAcceptedTermsAndConditions(checkedState);
  };
  const isVariableFirstPaymentWithPlan = billingRequest.payment_request && plan;
  const bank_name = selectedInstitution?.name || "bank";
  const hasMandateRequest = Boolean(billingRequest?.mandate_request);
  const bankAuthorisationReady = Boolean(
    bankAuthorisation && bankAuthorisation.url
  );

  const isMobileAuthorisation = isOneOffPaymentFlow(billingRequest) && isMobile;

  const scheme = billingRequest?.mandate_request
    ?.scheme as AvailableDebitSchemeEnum;
  const paymentRequestScheme = billingRequest?.payment_request
    ?.scheme as Scheme;

  // show creditor details panel only for becs, becs_nz, sepa_core
  const shouldShowCreditorDetails = [
    AvailableDebitSchemeEnum.Becs,
    AvailableDebitSchemeEnum.BecsNz,
    AvailableDebitSchemeEnum.SepaCore,
  ].includes(scheme);

  const isSepaIBPFlow = isSepaIBPScheme(billingRequest);
  const isACHMandate =
    billingRequest?.mandate_request?.scheme === AvailableDebitSchemeEnum.Ach;
  const consentType =
    (billingRequest.mandate_request?.consent_type as ConsentType) ?? undefined;

  const termsAndConditionsText = (
    <Trans id="confirm-details-page.bank-details.terms-of-use-confirmation">
      I confirm I have read and agree to the{" "}
      <BrandedLink
        variant={ButtonVariant.Inline}
        href={getTranslatedLink(Link.PayerTerms, i18n, billingRequest)}
        target="_blank"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Text weight={FontWeight.SemiBold}>Payer Terms of Use</Text>
      </BrandedLink>
    </Trans>
  );

  const termsAndConditions = (
    <TermsAndConditions
      checked={acceptedTermsAndConditions}
      onChecked={onChecked}
      showError={!acceptedTermsAndConditions && termsAndConditionsError}
      text={termsAndConditionsText}
    />
  );

  const renderButtonBasedOnDevice = () => (
    <BankAuthorisationLink
      push={push}
      page="BankConfirm"
      runtimeMode={runtimeMode}
      billingRequest={billingRequest}
      billingRequestFlow={billingRequestFlow}
      bankAuthorisation={bankAuthorisation}
      payerTheme={payerTheme}
      layout={[ButtonLayout.Full, ButtonLayout.Inline]}
      onAuthorisationClicked={onConfirm}
      bankAuthorisationFlowType={
        isDesktop
          ? BANK_AUTHORISATION_FLOW_TYPE.DESKTOP
          : BANK_AUTHORISATION_FLOW_TYPE.MOBILE
      }
      disabled={
        (isMobileAuthorisation && !bankAuthorisationReady) ||
        (isSepaIBPFlow && !acceptedTermsAndConditions)
      }
      preventRedirect={preventRedirectingToBank}
      data-testid="billing-request.bank-confirm.default-cta-button"
    >
      {isDesktop ? (
        <Trans id="confirm-details-page.form.confirm-and-continue-button">
          Confirm and continue
        </Trans>
      ) : (
        <Trans id="bank-connect-page.bank-authorization.link">
          Continue to your bank
        </Trans>
      )}
    </BankAuthorisationLink>
  );

  const isAchOrPad =
    billingRequest?.mandate_request?.scheme &&
    [
      `${AvailableDebitSchemeEnum.Ach}`,
      `${AvailableDebitSchemeEnum.Pad}`,
    ].includes(billingRequest.mandate_request.scheme);

  const { consentTypeContentText } = getDisplayedConsentInfo(
    scheme,
    consentType
  );

  const isOneTimePaymentWithRecurringConsent =
    consentType &&
    billingRequest.payment_request &&
    (billingRequest.subscription_request ||
      billingRequest.instalment_schedule_request ||
      [ConsentType.Sporadic, ConsentType.Sporadic].includes(consentType));

  const subheadingForACHAndPAD = !consentType ? (
    <Trans>We need your consent to set up this authorization</Trans>
  ) : (
    <>
      <Trans>
        We need your consent to set up this {consentTypeContentText}
      </Trans>{" "}
      {isOneTimePaymentWithRecurringConsent && (
        <Trans>and one-time payment</Trans>
      )}
    </>
  );

  const showPDFMandateLink = Boolean(
    mandatePDFLink && !(isAchOrPad && consentType)
  );

  return (
    <Box>
      {/* Display Header */}
      <H2
        size={6}
        weight={600}
        data-testid="billing-request.bank-confirm.header"
      >
        {customiseForScheme({
          scheme: hasMandateRequest ? (scheme as Scheme) : paymentRequestScheme,
          key: "billing-request.bank-confirm.header",
          params: { bank_name, billingRequest, plan },
        })}
      </H2>

      {isAchOrPad && (
        <P size={3} weight={FontWeight.Light} spaceAbove={1}>
          {subheadingForACHAndPAD}
        </P>
      )}
      {billingRequest.payment_request && (
        <>
          <Space v={0.5} />
          <P size={3} weight={FontWeight.Light}>
            {customiseForScheme({
              scheme: paymentRequestScheme,
              key: "billing-request.bank-confirm.header-notification",
              params: {
                billingRequest,
                plan,
              },
            })}
          </P>
        </>
      )}
      <Space v={1.5} />

      {customiseForScheme({
        scheme: (scheme as Scheme) || paymentRequestScheme,
        key: "billing-request.bank-confirm.body",
        params: {
          billingRequest,
          billingRequestFlow,
          bankAuthorisation,
          selectedInstitution,
          plan,
          instalmentTemplate,
          paylink,
          onChangeInstitution,
          onAddAnotherAccount,
          onEditCustomerDetails,
          payerTheme,
          shouldShowCreditorDetails,
          setRememberPayerDetails,
          scheme,
        },
      })}

      {!isSepaIBPFlow && <Space v={2} />}
      {hasMandateRequest && DirectDebitSchemes.includes(scheme) ? (
        <>
          {shouldShowSelfAuthorisationCheck && (
            <SelfPaymentAuthorisationCheck
              setIsSelfAuthorised={setIsSelfAuthorised}
              isSelfAuthorised={isSelfAuthorised}
              sendEvent={sendEvent}
              scheme={scheme}
            />
          )}

          {isSepaIBPFlow && termsAndConditions}

          <Box
            layout="flex"
            flexDirection="column"
            alignItems={AlignItems.Center}
          >
            {isACHMandate && (
              <Box spaceBelow={1.5}>
                <P
                  size={2}
                  textAlign={TextAlign.Center}
                  color={ColorPreset.TextOnLight_03}
                >
                  <Trans>
                    By continuing, you consent to the terms of your ACH Debit
                    Authorization, GoCardless&apos; E-Sign Terms, and your name
                    being used in print as an electronic representation of this
                    consent.
                  </Trans>
                </P>
              </Box>
            )}

            {isVariableFirstPaymentWithPlan ? (
              <Box spaceBelow={1.5}>
                <P
                  size={2}
                  textAlign={TextAlign.Center}
                  color={ColorPreset.TextOnLight_03}
                >
                  <>
                    GoCardless needs your consent to trigger a one-off payment
                    (as per its{" "}
                    <PayerTermsLink
                      billingRequest={billingRequest}
                      i18n={i18n}
                      payerTheme={payerTheme}
                    />
                    ) and set up a recurring Direct Debit payment from your
                    account. By continuing, you agree to this and that you can
                    authorise Direct Debits on this account yourself.
                  </>
                </P>
              </Box>
            ) : null}

            {isVariableFirstPaymentWithPlan ? (
              renderButtonBasedOnDevice()
            ) : (
              <BrandedButton
                data-testid="billing-request.bank-confirm.direct-debit-cta-button"
                backgroundColor={getBrandColorFor(
                  BrandedComponentType.Button,
                  payerTheme
                )}
                type="submit"
                leftIcon={Glyph.Padlock}
                size={[ButtonSize.Md, null, ButtonSize.Lg]}
                layout={ButtonLayout.Inline}
                variant={ButtonVariant.PrimaryOnLight}
                onClick={onConfirm}
              >
                {translateForScheme({
                  scheme: billingRequest?.mandate_request
                    ?.scheme as AvailableDebitSchemeEnum,
                  translationKey: "confirm-details-page.form.confirm-button",
                })}
              </BrandedButton>
            )}
            {/* Todo: Change the text inside based on the scheme */}
            {/* Don't show mandate pdf link  */}
            {/* if for some reason our API to mandate pdf fails  */}
            {showPDFMandateLink && (
              <Box spaceAbove={1.5}>
                <ViewDirectDebit
                  payerTheme={payerTheme}
                  mandatePDFLink={mandatePDFLink}
                  billingRequest={billingRequest}
                  sendEvent={sendEvent}
                />
              </Box>
            )}
            {isACHMandate && (
              <Box spaceAbove={mandatePDFLink ? 1 : 1.5}>
                <ViewESignTerms
                  payerTheme={payerTheme}
                  eSignTermsLink={eSignTermsLink}
                  billingRequest={billingRequest}
                  sendEvent={sendEvent}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          {isSepaIBPFlow && termsAndConditions}
          <TermsAndCondition
            payerTheme={payerTheme}
            billingRequest={billingRequest}
          />
          <Space v={2} />
          <Box
            width="100%"
            layout="flex"
            justifyContent={JustifyContent.Center}
          >
            {renderButtonBasedOnDevice()}
          </Box>
        </>
      )}
    </Box>
  );
};

const BankConfirmViewDefaultBody = ({
  billingRequest,
  billingRequestFlow,
  selectedInstitution,
  plan,
  instalmentTemplate,
  paylink,
  onChangeInstitution,
  onAddAnotherAccount,
  onEditCustomerDetails,
  payerTheme,
  shouldShowCreditorDetails,
  scheme,
  bankAuthorisation,
  setRememberPayerDetails,
}: BankConfirmViewBodyProps) => {
  const shouldShowCustomerDetails = customerDetailsExist({
    billingRequest,
  });
  const isPAD =
    billingRequest.mandate_request?.scheme === AvailableDebitSchemeEnum.Pad;
  const removeBottomBorderRadiusCSS = isPAD
    ? { "div:nth-child(2)": { borderRadius: 0 } }
    : undefined;

  const isVariableFirstPaymentWithPlan = billingRequest.payment_request && plan;
  const { payment_request, ...brFromVariableFirstPaymentWithPlan } =
    billingRequest;

  const shouldDisplayPayerPreference =
    useShouldDisplayCustomerPreferences(billingRequest);

  return (
    <>
      <LabelledPanel
        data-testid="billing-request.bank-confirm.default-body"
        heading={
          <XYGrid rowGap={1.5}>
            <Interpose node={<Separator spacing={0} />}>
              <BillingRequestDescription
                billingRequest={billingRequest}
                billingRequestFlow={billingRequestFlow}
                plan={plan}
                instalmentTemplate={instalmentTemplate}
                paylink={paylink}
                page={Routes.BankConfirm}
                // Require the extended confirmation for the mandate, if the scheme
                // requires it.
                mandateRequestExtendedConfirmation={true}
              />
              {isVariableFirstPaymentWithPlan && (
                <BillingRequestDescription
                  billingRequest={brFromVariableFirstPaymentWithPlan}
                  billingRequestFlow={billingRequestFlow}
                  plan={plan}
                  instalmentTemplate={instalmentTemplate}
                  paylink={paylink}
                  page={Routes.BankConfirm}
                  // Require the extended confirmation for the mandate, if the scheme
                  // requires it.
                  mandateRequestExtendedConfirmation={true}
                />
              )}
            </Interpose>
          </XYGrid>
        }
        headerBg={Color.Greystone_100}
        headerBorder={Color.Greystone_100}
        gutterH={2}
        gutterV={2}
        css={removeBottomBorderRadiusCSS}
      >
        <Interpose node={<Separator spacing={[[2, 0]]} />}>
          {shouldShowCreditorDetails && (
            <CreditorDetails
              billingRequest={billingRequest}
              billingRequestFlow={billingRequestFlow}
              scheme={scheme}
            />
          )}

          {shouldShowCustomerDetails && (
            <ConfirmCustomerDetails
              billingRequest={billingRequest}
              billingRequestFlow={billingRequestFlow}
              onEditCustomerDetails={onEditCustomerDetails}
              payerTheme={payerTheme}
              bankAuthorisation={bankAuthorisation}
            />
          )}

          <BankDetails
            billingRequest={billingRequest}
            billingRequestFlow={billingRequestFlow}
            institution={selectedInstitution}
            onChangeInstitution={onChangeInstitution}
            onAddAnotherAccount={onAddAnotherAccount}
            payerTheme={payerTheme}
          />
          {/* TODO add translations: PYP-33: https://gocardless.atlassian.net/browse/PYP-33*/}
          {shouldDisplayPayerPreference && (
            <Checkbox
              name="rememberDetails"
              data-testid="billing-request.bank-confirm.remember-payer-details"
              description={
                <P preset={TypePreset.Caption_01} spaceAbove={0.25}>
                  Pay faster with GoCardless, your details are securely saved to
                  speed up future payments including at other businesses.{" "}
                  <LearnMoreDialog />
                </P>
              }
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setRememberPayerDetails(e.target.checked);
              }}
            >
              Remember my details for future payments
            </Checkbox>
          )}
        </Interpose>
      </LabelledPanel>
      {isPAD && billingRequest.mandate_request && (
        <PADLegalDisclaimer
          mandateRequest={billingRequest.mandate_request}
          isConfirmationPage
        />
      )}
    </>
  );
};

const LearnMoreDialog = () => {
  const [open, setOpen] = useState(false);
  const toggleDialog = () => setOpen((previous) => !previous);

  return (
    <>
      <PlainButton
        effect={HoverEffect.TextDecoration}
        textDecoration="underline"
        onClick={toggleDialog}
        data-testid="billing-request.bank-confirm.remember-payer.learn-more"
      >
        <Text preset={TypePreset.Caption_01} weight={FontWeight.SemiBold}>
          Learn more
        </Text>
      </PlainButton>
      <Dialog
        open={open}
        closeAction={{ label: "Close", onClose: toggleDialog }}
        header={
          <>
            <H4 id="demoTitle" preset={TypePreset.Body_02}>
              Pay Faster, Securely with GoCardless
            </H4>
          </>
        }
        footer={
          <Box layout="flex" width="100%" flexDirection="row-reverse">
            <Button
              variant={ButtonVariant.PrimaryOnLight}
              size={ButtonSize.Sm}
              layout={[ButtonLayout.Full, null, ButtonLayout.Inline]}
              onClick={toggleDialog}
            >
              Close
            </Button>
          </Box>
        }
      >
        <P
          preset={TypePreset.Body_02}
          color={ColorPreset.TextOnLight_02}
          spaceBelow={2}
          data-testid="billing-request.bank-confirm.remember-payer.dialog"
        >
          Opt in to GoCardless’s Remember Me feature to speed up future payments
          across thousands of businesses—securely and hassle-free.
        </P>
        <P>
          <UL color={ColorPreset.TextOnLight_02} spaceBetween={1}>
            <LI>
              <Text weight={FontWeight.SemiBold}>Safe and secure -</Text> Your
              details are encrypted. We use personal date as described in our{" "}
              <PlainLink
                href="https://gocardless.com/privacy/"
                textDecoration="underline"
              >
                Privacy Notice
              </PlainLink>
            </LI>
            <LI>
              <Text weight={FontWeight.SemiBold}>Quick & Easy - </Text>No need
              to re-enter details every time you pay.
            </LI>
            <LI>
              <Text weight={FontWeight.SemiBold}>Extra Protection -</Text> We’ll
              send a verification code to your email when you use a new device.
            </LI>
          </UL>
        </P>
        <P
          preset={TypePreset.Body_02}
          color={ColorPreset.TextOnLight_02}
          spaceAbove={2}
        >
          You can opt out and remove your saved details at any time, by clicking
          the opt-out link in the email confirmation or contacting us directly.
          View support{" "}
          <PlainLink
            href="https://support.gocardless.com/hc/en-gb/articles/17145061027484-Contacting-GoCardless-Support"
            textDecoration="underline"
          >
            here.
          </PlainLink>
        </P>
      </Dialog>
    </>
  );
};

export {
  BankConfirmViewDefaultBody,
  PaymentAgreementBody as BankConfirmViewPaymentAgreementBody,
};
export default BankConfirmView;
