import {
  Table,
  Color,
  Text,
  useTheme,
  TypePreset,
  FontWeight,
  MoneyTextVariant,
  MoneyText,
  P,
  Tooltip,
  ButtonGutter,
  ButtonSize,
  ButtonVariant,
  Glyph,
  IconButton,
  Box,
  AlignItems,
  Breakpoint,
} from "@gocardless/flux-react";
import { convertToLocaleDateString } from "src/common/date-time";
import { useI18n } from "src/common/i18n";
import { SelectOrdinal, Trans } from "@lingui/macro";
import {
  SourceCurrencyEnum as Currency,
  InstalmentTemplateResource,
} from "@gocardless/api/dashboard/types";
import { useIsBreakpoint } from "src/components/shared/hooks/useIsBreakpoint";

import { InstalmentsListSummaryHeaderCell } from "./InstalmentsListSummaryHeaderCell";
import { InstalmentsListSummaryCell } from "./InstalmentsListSummaryCell";

export interface InstalmentsDetailTableProps {
  totalAmount: number;
  instalments: InstalmentTemplateResource["instalments"];
  currency: Currency;
}

export const InstalmentsDetailTable = ({
  instalments,
  currency,
  totalAmount,
}: InstalmentsDetailTableProps) => {
  const { theme } = useTheme();
  const [locale] = useI18n();
  const isMobile = useIsBreakpoint({
    max: Breakpoint.Sm,
  });

  return (
    <Table.Container
      css={{
        width: "100%",
        overflowX: "scroll",
        display: isMobile ? "block" : "table",
        border: "none",
        padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
        backgroundColor: Color.White,
      }}
    >
      <thead>
        <Table.Row>
          <InstalmentsListSummaryHeaderCell
            noLeftPadding
            noRightPadding
            isMobile={isMobile}
          >
            Instalment
          </InstalmentsListSummaryHeaderCell>
          <InstalmentsListSummaryHeaderCell isMobile={isMobile}>
            <Box layout="flex" alignItems={AlignItems.Center}>
              <Box spaceAfter={isMobile ? 0.25 : 0.5}>
                <Trans>Charge date</Trans>
              </Box>
              <Tooltip
                message={
                  // TODO: ADD TRANSLATION
                  <>Charge dates can be delayed by weekends and holidays.</>
                }
                positionStrategy="absolute"
              >
                {(triggerProps) => {
                  return (
                    <IconButton
                      icon={Glyph.Tooltip}
                      label="Settings"
                      variant={ButtonVariant.TextOnLight}
                      size={{ base: ButtonSize.Sm, gutters: ButtonGutter.Sm }}
                      {...triggerProps}
                    />
                  );
                }}
              </Tooltip>
            </Box>
          </InstalmentsListSummaryHeaderCell>
          <InstalmentsListSummaryHeaderCell
            noRightPadding
            alignRight
            isMobile={isMobile}
          >
            <Trans>Amount</Trans>
          </InstalmentsListSummaryHeaderCell>
        </Table.Row>
      </thead>

      <tbody
        css={{
          width: "100%",
          whiteSpace: "nowrap",
        }}
      >
        {instalments?.map(({ amount, charge_date: chargeDate }, index) => {
          const isLastRow = index + 1 === instalments.length;
          const date = chargeDate ? (
            convertToLocaleDateString({
              date: chargeDate as Date,
              options: {
                day: "2-digit",
                month: "short",
                year: "numeric",
              },
              locale,
            })
          ) : (
            <>Confirmed by email</>
          );
          return (
            <Table.Row key={index.toString()}>
              <InstalmentsListSummaryCell
                isMobile={isMobile}
                paddingBottom={isLastRow}
                noLeftPadding
                noRightPadding
              >
                <P weight={FontWeight.SemiBold}>
                  <SelectOrdinal
                    value={index + 1}
                    one="#st"
                    two="#nd"
                    few="#rd"
                    other="#th"
                  />
                </P>
              </InstalmentsListSummaryCell>
              <InstalmentsListSummaryCell
                isMobile={isMobile}
                paddingBottom={isLastRow}
              >
                {date}
              </InstalmentsListSummaryCell>
              <InstalmentsListSummaryCell
                isMobile={isMobile}
                paddingBottom={isLastRow}
                alignRight
                noRightPadding
              >
                <MoneyText
                  format="short"
                  preset={TypePreset.Body_03}
                  weight={FontWeight.SemiBold}
                  variant={MoneyTextVariant.Flat}
                  amount={Number(amount) / 100}
                  currency={currency}
                  locale={locale}
                />
              </InstalmentsListSummaryCell>
            </Table.Row>
          );
        })}
        <Table.Row>
          <InstalmentsListSummaryCell
            isMobile={isMobile}
            topBorder
            noLeftPadding
            noRightPadding
          />
          <InstalmentsListSummaryCell
            isMobile={isMobile}
            topBorder
            alignRight
            noRightPadding
          >
            <Text preset={TypePreset.Body_02} weight={FontWeight.SemiBold}>
              <Trans>Total:</Trans>
            </Text>
          </InstalmentsListSummaryCell>
          <InstalmentsListSummaryCell
            isMobile={isMobile}
            topBorder
            alignRight
            noRightPadding
          >
            <MoneyText
              format="short"
              preset={TypePreset.Body_03}
              weight={FontWeight.SemiBold}
              variant={MoneyTextVariant.Flat}
              amount={totalAmount / 100}
              currency={currency}
              locale={locale}
            />
          </InstalmentsListSummaryCell>
        </Table.Row>
      </tbody>
    </Table.Container>
  );
};
