import {
  ButtonVariant,
  Color,
  FontWeight,
  Link,
  LinkProps,
  useTheme,
} from "@gocardless/flux-react";

import { CSSForButtonAppearance, CSSForLinkAppearance } from "..";

export interface BrandedLinkProps extends Omit<LinkProps, "variant"> {
  textColor?: string;
  backgroundColor?: string;
  variant?: ButtonVariant;
  disabled?: boolean;
}

/**
 * Branded link will override the default colors with merchant preferable
 * colors for link, if it is being provided
 */

/**
 * Ex: for rounded variant
 * <BrandedLink
 *    variant={ButtonVariant.Rounded}
 *    backgroundColor={hexCode}
 *    ...
 * />
 *
 * Ex: for footer's link
 *  <BrandedLink
 *    textColor={BrandedFooterLinkColor}
 *    weight={FontWeight.Bold}
 *    ...
 * />
 *
 * Ex: for other variants
 * <BrandedLink
 *    textColor={hexCode}
 *    ...
 * />
 *
 * Note: No text color should be passed for rounded variant
 */
const BrandedLink = (props: BrandedLinkProps) => {
  const {
    textColor,
    variant = ButtonVariant.Inline,
    backgroundColor,
    children,
    ...rest
  } = props;
  const { theme } = useTheme();
  const linkDefaultStyle = {
    color: theme.color(Color.Greystone_1400),
    fontWeight: FontWeight.Light,
  };

  const roundedButtons = [
    ButtonVariant.PrimaryOnDark,
    ButtonVariant.PrimaryOnLight,
  ];

  // for rounded link, css will be different
  // i.e. backgroundcolor is required for rounded link
  if (roundedButtons.includes(variant)) {
    return (
      <Link
        css={backgroundColor ? CSSForButtonAppearance(backgroundColor) : {}}
        {...rest}
        variant={variant}
      >
        {children}
      </Link>
    );
  } else if (textColor) {
    return (
      <Link
        css={{ ...linkDefaultStyle, ...CSSForLinkAppearance(textColor) }}
        {...rest}
        variant={variant}
      >
        {children}
      </Link>
    );
  }
  return (
    <Link variant={variant} {...rest} style={linkDefaultStyle}>
      {children}
    </Link>
  );
};

export default BrandedLink;
