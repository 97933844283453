import {
  BankAuthorisationResource,
  BillingRequestFlowResource,
  BillingRequestResource,
} from "@gocardless/api/dashboard/types";
import {
  ButtonLayout,
  ButtonSize,
  ButtonVariant,
  FontWeight,
  Glyph,
  PlainButton,
  ResponsiveValue,
  TypePreset,
} from "@gocardless/flux-react";
import { OptimizelyContext } from "@optimizely/react-sdk";
import React, { MouseEventHandler, useContext, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";
import {
  BrandedComponentType,
  getBankAuthorisationLink,
  getBrandColorFor,
  isEligibleForSingleTabFlow,
  isUKPaymentFlow,
} from "src/common/utils";
import { PayerThemeType } from "src/state";
import { RuntimeMode } from "src/state/RuntimeModeInitialiser";
import { captureException } from "src/common/sentry";
import { trackBankAuthorisationVisit } from "src/common/api";
import { BANK_AUTHORISATION_FLOW_TYPE } from "src/common/consts";
import { Logger } from "src/common/logger";
import { TrackingEvents } from "src/common/trackingEvents";
import { useSegment } from "src/shared/Segment/useSegment";

import BrandedButton from "../shared/BrandedComponents/BrandedButton";
import BrandedLink from "../shared/BrandedComponents/BrandedLink";
import { Routes } from "../shared/Router";

export const BANK_WAIT_TRANSITION_DELAY_MS = 4000;

interface BankAuthorisationLinkProps {
  page: string;
  runtimeMode?: RuntimeMode;
  children: React.ReactNode;
  bankAuthorisationFlowType: BANK_AUTHORISATION_FLOW_TYPE;
  push: (route: Routes, keyvals: Record<string, unknown>) => void;
  billingRequest?: BillingRequestResource;
  billingRequestFlow?: BillingRequestFlowResource;
  bankAuthorisation?: BankAuthorisationResource;
  onAuthorisationClicked?: () => void;
  payerTheme?: PayerThemeType;
  disabled?: boolean;
  preventRedirect?: boolean;
  variant?: BankAuthorisationLinkVariant;
  layout?: ResponsiveValue<ButtonLayout>;
}

export enum BankAuthorisationLinkVariant {
  PLAIN = "PLAIN",
  BRANDED = "BRANDED",
}

const BankAuthorisationLink = ({
  push,
  page,
  children,
  bankAuthorisationFlowType,
  onAuthorisationClicked,
  billingRequest,
  billingRequestFlow,
  bankAuthorisation,
  payerTheme,
  runtimeMode,
  disabled,
  preventRedirect,
  variant = BankAuthorisationLinkVariant.BRANDED,
  layout = ButtonLayout.Full,
  ...rest
}: BankAuthorisationLinkProps) => {
  const { sendEventAndRedirect } = useSegment();
  const log = Logger("BankAuthorisationLink");
  const { optimizely } = useContext(OptimizelyContext);

  const [clickCounter, setClickCounter] = useState(0);

  const bankAuthorisationUrl = getBankAuthorisationLink(
    billingRequest,
    billingRequestFlow,
    bankAuthorisation,
    optimizely
  );

  const openInCurrentTab = isEligibleForSingleTabFlow(
    runtimeMode,
    billingRequest
  );

  const exceptionMetadata = {
    runtimeMode,
    bankAuthorisationFlowType,
    singleTabFlow: openInCurrentTab,
  };

  const onClick: MouseEventHandler = (e) => {
    // NOTE: On Mobile, we trigger the redirect from the anchor href, not in this onClick.
    // Whereas on Desktop, we do trigger the redirect in this onClick using window.open.

    // NOTE: Be careful with code in this onClick callback, it must
    // not throw an uncaught exception or navigate the current page too quickly
    // or call event.preventDefault() as that would stop the bank app
    // deeplink redirect from working correctly, especially on mobile.

    if (onAuthorisationClicked) {
      try {
        // Useful for parent containers to perform any parent-specific tracking,
        // wrapped in a try block to ensure it does not intefere with the redirect.
        onAuthorisationClicked();
      } catch (error) {
        log({ error });
        captureException(error as Error, { additionalData: exceptionMetadata });
      }
    }

    if (disabled || preventRedirect) {
      e.preventDefault();
      return;
    }

    if (billingRequest && isUKPaymentFlow(billingRequest)) {
      try {
        trackBankAuthorisationVisit(
          bankAuthorisation as BankAuthorisationResource,
          bankAuthorisationFlowType
        );
      } catch (error) {
        log({ error });
        captureException(error as Error, { additionalData: exceptionMetadata });
      }
    }

    if (isDesktop) {
      sendEventAndRedirect(
        TrackingEvents.BANK_AUTHORISATION_CONTINUE_ON_DESKTOP_CLICKED,
        () =>
          window.open(
            bankAuthorisationUrl,
            openInCurrentTab ? "_self" : "_blank"
          )
      );
    }

    if (clickCounter === 0) {
      // The payer should already be in a new tab or in the bank app.
      // We want to redirect to the bank wait page in the background.
      // keeping this in a timeout also prevents race conditions where
      // we mistakenly redirect to BankConfirm without loading the auth url.
      setTimeout(() => {
        push(Routes.BankWait, {
          origin: page,
          reason: "waiting for payer to authorise with bank",
        });
      }, BANK_WAIT_TRANSITION_DELAY_MS);
    }

    if (clickCounter === 2) {
      captureException(Error(`Navigation failed to: ${bankAuthorisationUrl}`), {
        additionalData: exceptionMetadata,
      });
    }
    setClickCounter(clickCounter + 1);
  };

  const renderAuthComponent = () => {
    if (isMobile) {
      return (
        <BrandedLink
          backgroundColor={getBrandColorFor(
            BrandedComponentType.Button,
            payerTheme
          )}
          variant={ButtonVariant.PrimaryOnLight}
          leftIcon={Glyph.Padlock}
          rightIcon={Glyph.ArrowForward}
          size={ButtonSize.Lg}
          layout={layout}
          disabled={disabled}
          data-testid="bank-auth-link-button"
          {...rest}
          href={bankAuthorisationUrl ?? "#"}
          target={openInCurrentTab ? "_self" : "_blank"}
          onClick={onClick}
        >
          {children}
        </BrandedLink>
      );
    }

    if (variant === BankAuthorisationLinkVariant.BRANDED) {
      return (
        <BrandedButton
          backgroundColor={getBrandColorFor(
            BrandedComponentType.Button,
            payerTheme
          )}
          variant={ButtonVariant.PrimaryOnLight}
          rightIcon={Glyph.ArrowForward}
          size={ButtonSize.Lg}
          layout={layout}
          disabled={disabled}
          data-testid="bank-auth-link-button"
          {...rest}
          onClick={onClick}
        >
          {children}
        </BrandedButton>
      );
    }

    return (
      <PlainButton
        weight={FontWeight.SemiBold}
        preset={TypePreset.Body_01}
        disabled={disabled}
        data-testid="bank-auth-link-button"
        {...rest}
        onClick={onClick}
      >
        {children}
      </PlainButton>
    );
  };

  return renderAuthComponent();
};

export default BankAuthorisationLink;
