import { BillingRequestResource } from "@gocardless/api/dashboard/types";
import { useContext } from "react";
import { OptimizelyContext } from "@optimizely/react-sdk";

export const useShouldDisplayCustomerPreferences = (
  billingRequest: BillingRequestResource
): Boolean => {
  const { optimizely } = useContext(OptimizelyContext);

  const optimizely_decision = optimizely?.decide(
    "payer_prop__remember_me",
    [],
    billingRequest.id
  );

  return !!(
    optimizely_decision?.enabled &&
    billingRequest.mandate_request?.scheme === "bacs"
  );
};
