import {
  AvailableDebitSchemeEnum,
  ConsentType,
  SourceCurrencyEnum as Currency,
} from "@gocardless/api/dashboard/types";
import { ReactNode } from "react";
import { getTranslatedPaymentPeriodicityMessage } from "src/common/frequency";
import { getDisplayedConsentInfo } from "src/common/scheme-translations/DisplayedConsentInformation/getDisplayedConsentInfo";
import { BillingRequestResource } from "src/dropin/types";
import { Scheme } from "src/common/scheme";

import { getFirstInstalmentPaymentMessage } from "../../utils";

interface DisplayedValues {
  scheme: Scheme;
  displayedConsentType: ReactNode;
  toolTipContent: ReactNode;
  paymentMethodDescriptions: string[];
  mandateDescription: ReactNode;
  currency: Currency;
  creditorName?: string;
  amount?: number;
  periodicityMessage?: string | ReactNode;
  heading?: string;
  recurringPaymentsCount?: number;
}

export const getDisplayedValues = (
  billingRequest: BillingRequestResource,
  defaultMandateDescription?: ReactNode
): DisplayedValues => {
  const scheme =
    (billingRequest.mandate_request?.scheme as AvailableDebitSchemeEnum) ??
    undefined;
  const { consentTypeText, toolTipContent, instalmentConsentTypeText } =
    getDisplayedConsentInfo(
      scheme,
      billingRequest.mandate_request?.consent_type as ConsentType
    );
  const baseValues = {
    scheme,
    displayedConsentType: consentTypeText,
    toolTipContent: toolTipContent,
    paymentMethodDescriptions: [
      billingRequest.mandate_request?.description ?? undefined,
      billingRequest.mandate_request?.constraints?.payment_method,
    ].filter((str): str is string => Boolean(str)),
    mandateDescription: defaultMandateDescription,
    currency: billingRequest.mandate_request?.currency as Currency,
    creditorName: billingRequest.creditor_name,
    amount: undefined,
    periodicityMessage: undefined,
  };

  if (billingRequest.subscription_request) {
    return {
      ...baseValues,
      heading: billingRequest.subscription_request?.name ?? undefined,
      mandateDescription: undefined,
      amount: parseInt(
        billingRequest.subscription_request?.amount?.toString() || "0"
      ),
      recurringPaymentsCount:
        parseInt(
          billingRequest.subscription_request?.count?.toString() || "0"
        ) ?? undefined,
      periodicityMessage: getTranslatedPaymentPeriodicityMessage(
        billingRequest.subscription_request
      ),
    };
  }

  if (billingRequest.instalment_schedule_request) {
    const { instalments } = billingRequest.instalment_schedule_request;

    const instalmentPaymentCount = Array.isArray(instalments)
      ? instalments.length
      : undefined;

    const instalmentValues = {
      ...baseValues,
      displayedConsentType: instalmentConsentTypeText,
      heading: billingRequest.instalment_schedule_request?.name,
      mandateDescription: undefined,
      instalmentPaymentsCount: instalmentPaymentCount,
    };

    if (!Array.isArray(instalments)) {
      return instalmentValues;
    }
    const chargeDate = instalments[0]?.charge_date ?? "";
    const instalmentDate = new Date(chargeDate);
    const periodicityMessage = getFirstInstalmentPaymentMessage(instalmentDate);

    return {
      ...instalmentValues,
      periodicityMessage,
    };
  }

  return baseValues;
};
