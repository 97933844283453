import { bankAuthorisationTrackVisit } from "@gocardless/api/dashboard/bank-authorisation/fetch";
import {
  BankAuthorisationResource,
  BillingRequestFlowResource,
} from "@gocardless/api/dashboard/types";
import { billingRequestFlowTrack } from "@gocardless/api/dashboard/billing-request-flow";
import { NonSegmentTrackingEvents } from "src/common/nonSegmentTrackingEvents";

// Track bank authorisation visit for Instant Bank Pay Flows
export const trackBankAuthorisationVisit = (
  bankAuthorisationResource: BankAuthorisationResource,
  flow: string | undefined
) => {
  const request = bankAuthorisationTrackVisit(bankAuthorisationResource.id, {
    payer_authorisation_visit_flow: flow,
  });

  return createRequestWithTimeout(request);
};

export const trackBillingRequestFlowEvents = (
  event_name: NonSegmentTrackingEvents,
  billingRequestFlow?: BillingRequestFlowResource
) => {
  if (!billingRequestFlow) {
    return;
  }

  const request = billingRequestFlowTrack(billingRequestFlow.id, {
    event_name,
  });

  return createRequestWithTimeout(request);
};

const createRequestWithTimeout = (request: Promise<{}>) => {
  const timeout = new Promise((res) => setTimeout(() => res(true), 500));
  return Promise.race([request, timeout]);
};
